@import "variables";

@import "theme/theme";

@import "~select2/src/scss/core";

@import "~dropzone/src/basic";
@import "~dropzone/src/dropzone";

.card {
    box-shadow: none;
    border-style: solid;
    border-color: #cccccc;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 4px;
    border-left-width: 1px;

    border-radius: 16px;

    .card-header {
        border-width: 1px;

        i {
            font-size: 18px;
        }
    }

    .card-header .card-title {
        margin-bottom: 0;
        color: $primary;
        font-weight: bold;
    }
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--theme-light);
}

.btn {
    border-radius: 50rem; 
}

.actions {
    width: 130px;
}

.dropzone {
    border: none;
    padding: 0px;
}

.dropzone .dz-message {
    margin: 0;
    margin-bottom: 20px;
}

.sticky-scroll {
    position: sticky;
    top: 70px;
}

.form-control {
    padding: .6rem .8rem;
}

.thumbnail-container img {
    max-width: 100%;
    max-height: 250px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 20px;
}